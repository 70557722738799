import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        redirect:'/index'
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/IndexView.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/newsView.vue'),
    },
    {
        path: '/news/newsId/:newsId',
        name: 'newsDetails',
        component: () => import("../views/newsDetailsView.vue")
    },
    {
        path: '/people',
        name: 'people',
        component: () => import('../views/peopleView.vue')
    },
    {
        path: '/people/peopleId/:peopleId',
        name: 'peopleDetailsView',
        component: () => import('../views/peopleDetailsView.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
    }
]

const router = new VueRouter({
    routes
})

export default router
